import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-how-do-you-feel-modal',
  templateUrl: './how-do-you-feel-modal.component.html',
  styleUrls: ['./how-do-you-feel-modal.component.scss']
})
export class HowDoYouFeelModalComponent {

  @Input() message: any = '';

  constructor(public activeMode:NgbActiveModal) {
  }

}
