import {Component} from '@angular/core';
import {environment} from "../environments/environment";
import {DaisyUtilities, SymptomGroupDailyModel, SymptomGroupTitleModel, SymptomModel} from "@daisy2/core";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nursedaisy.patient.web';

  constructor() {

    if (environment.production) {
      console.log = function () {
      };
      console.error = function () {
      };
    }
  }
}


