<div class="nd-container">
  <div class="nd-header-section nd-cursor-pointer">
    <svg (click)="activeMode.dismiss(0)" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon-interactive/close">
        <g id="icon/close">
          <path id="Vector"
                d="M16.0788 5.59199L14.9038 4.41699L10.2454 9.07533L5.58711 4.41699L4.41211 5.59199L9.07044 10.2503L4.41211 14.9087L5.58711 16.0837L10.2454 11.4253L14.9038 16.0837L16.0788 14.9087L11.4204 10.2503L16.0788 5.59199Z"
                fill="#064491"/>
        </g>
      </g>
    </svg>
  </div>
  <div class="nd-content-section">
    <div class="nd-content-text">{{message}}</div>
  </div>
  <div class="nd-footer-section">
    <button class="nd-btn-primary-outline" (click)="activeMode.dismiss(1)">NOT GOOD</button>
    <button class="nd-btn-primary" (click)="activeMode.dismiss(0)">GOOD</button>
  </div>
</div>
